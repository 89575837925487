
import { httpPost } from '@/api';
import { computed, defineComponent, inject, nextTick, onMounted, onUnmounted, PropType, reactive, ref, shallowRef, toRaw, toRefs, watch } from 'vue';
import ErpFilter from './ErpFilter.vue';
import ErpTable from './ErpTable.vue';
import useVisible from '@/hooks/useVisible';
import { ErpTableOptions } from '@/types/type';
import { store } from '@/store';
import useLoading, { LoadingType } from '@/hooks/useLoading';
import XEUtils from 'xe-utils';
interface Sort {
  sortColumnCn: string | null;
  sortType: null | 1 | 2;
}

export default defineComponent({
  components: { ErpFilter, ErpTable },
  props: {
    url: {
      type: String as PropType<string>,
      default: '',
    },
    customParams: {
      default: {
        type: Object,
      },
    },
    columOptions: {
      type: Array as PropType<ErpTableOptions[]>,
    },
    id: {
      type: String as PropType<string>,
    },
    rowId: {
      type: String as PropType<string>,
      default: 'orderId',
    },
    // 渲染颜色
    colorRander: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // 获取自定义数据渲染值
    customResult: {
      type: String as PropType<string>,
      default: '',
    },
    // mock接口用
    isMock: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    //不要分页
    noPaging: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    queryFn: {
      type: Function as PropType<any>,
      default: null,
    },
    //表格设置功能
    hasConfig: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    //表格最大高度
    maxHeight: {
      type: Number as PropType<number>,
      default: 99999,
    },
    // todo props;
  },
  emits: ['row-click', 'getPageList', 'select-change'],
  setup(props, ctx) {
    const paginationParams = reactive({
      pageNum: 1,
      pageSize: 15,
      total: 0,
    });
    const pageRef = ref();
    const sort: Sort = reactive({
      sortType: null,
      sortColumnCn: null,
    });
    const { showVisible, visible, closeVisible } = useVisible();
    const { showLoading, closeLoading } = inject<LoadingType>('loading', useLoading());
    const queryForm = ref({});
    const listDatas = ref([]);
    const crmListRef = ref(null); // 父元素对象
    const erpTableRef = ref(null); // 表格对象元素
    const erpFilterRef = ref(null);
    const layoutHeight = ref(810);
    const filterLen = ref(0);
    const checkItem = ref([]);
    // 这里用shallowRef 因为数组层级比较大，只监听最外层
    let tableOptions = shallowRef([]);
    const completeLoad = computed(() => {
      // 表格加载且筛选项加载加载完再显示页面，防止抖动
      return true;
    });
    // 过滤不显示sort
    const formatFilterOption = ref([]) as any;
    const selectChange = (selection) => {
      ctx.emit('select-change', selection);
    };
    const rowClick = (row, column, event) => {
      // 设置激活row
      store.dispatch('setCurrentRow', {
        id: props.id + 'CurrentRow',
        data: toRaw(row),
      });
      ctx.emit('row-click', row, column, event);
    };
    // 设置列表记录缓存
    const setQueryParamsCache = () => {
      store.dispatch('setQueryParamsCache', {
        id: props.id + 'ListQuery',
        data: {
          sort: toRaw(sort),
          paginationParams: JSON.parse(JSON.stringify(paginationParams)),
          queryForm: JSON.parse(JSON.stringify(queryForm.value)),
          filterData: JSON.parse(JSON.stringify(checkItem.value)),
        },
      });
    };
    const setCurrentRow = () => {
      store.dispatch('getCurrentRow', props.id + 'CurrentRow').then((row: any) => {
        nextTick(() => {
          if (row) {
            const index = listDatas.value.findIndex((item: any) => {
              return item[props.rowId] === row[props.rowId];
            });
            const Table = (erpTableRef as any).value.table;
            Table.setCurrentRow(listDatas.value[index]);
          }
        });
      });
    };
    // 获取表格信息 ,来源参数比较多，自聚合到内部实现吧，外部参数不做控制
    const getTableDatas = () => {
      const params = {
        pageNum: paginationParams.pageNum,
        pageSize: paginationParams.pageSize,
        ...props.customParams,
        ...queryForm.value,
        ...sort,
      };
      // 设置过渡弹层
      showLoading('.erp-list__warp');
      httpPost(props.url, params, props.isMock).then((res) => {
        closeLoading();
        setQueryParamsCache();
        if (res.code === 200) {
          // 传入自定义资源属性名
          if (props.customResult && props.customResult !== '') {
            listDatas.value = res.result[props.customResult].list;
            paginationParams.total = Number(res.result[props.customResult].total);
          } else {
            listDatas.value = res.result.list || res.result;
            if (!props.noPaging) paginationParams.total = Number(res.result.total); //无分页
          }
          // hooks 翻页问题有bug 强行同步分页值
          nextTick(() => {
            if (!props.noPaging) pageRef.value.internalCurrentPage = paginationParams.pageNum; //无分页
          });
          // 返回后台接口数据
          ctx.emit('getPageList', res.result);
        } else {
          listDatas.value = [];
        }

        // 设置默认选中激活
        setCurrentRow();
      });
    };
    // 重置数据处理
    const restOptions = (data: ErpTableOptions[]) => {
      let tableOptionsValue = tableOptions.value as ErpTableOptions[];
      data.forEach((v) => {
        let index = tableOptionsValue.findIndex((vv) => vv.prop === v.prop);
        tableOptionsValue[index].value = v.value;
      });
      // tableOptions.value = toRaw(data) as any;
    };
    // 获取查询参数
    const queryParams = (filterParams, checkData, isSubmit) => {
      // 这里传递函数自定义拦截参数实现
      let customParams: any;
      queryForm.value = filterParams;
      if (typeof props.queryFn === 'function' && props.queryFn !== null) {
        customParams = Object.assign(queryForm.value, (props as any).queryFn(filterParams));
      }

      queryForm.value = Object.assign(queryForm.value, filterParams, customParams);
      if (isSubmit) {
        // 判断是不是手动确认搜索的数据
        paginationParams.pageNum = 1;
        queryForm.value = Object.assign({}, filterParams, customParams);
      }
      getTableDatas();
      const data = JSON.parse(JSON.stringify(checkData));
      filterLen.value = data.length;
      checkItem.value = data;
      visible.value = false;
    };
    /** 返回给其他父组件用查询参数 */
    const getQueryParams = () => {
      return Object.assign(
        queryForm.value,
        {
          pageNum: 1,
          pageSize: paginationParams.pageSize,
        },
        props.customParams,
        sort,
      );
    };
    /** 触发手动新增数量修改 */
    const getAddItem = (len: number, data) => {
      filterLen.value = len;
      checkItem.value = toRaw(data);
    };
    const getSortColumn = (data: Sort) => {
      sort.sortColumnCn = data.sortColumnCn;
      sort.sortType = data.sortType;
      getTableDatas();
    };
    const getColumn = (data) => {
      (erpFilterRef.value as any).addColumnItem(data);
      visible.value = true;
    };
    // 获取条数
    const currentSize = (size) => {
      paginationParams.pageSize = size;
      getTableDatas();
    };
    // 获取页码
    const currentChange = (page) => {
      paginationParams.pageNum = page;
      getTableDatas();
    };
    /**
     * 描述
     * @date 2021-01-29
     * @param {any} iskeep=true 是否保持参数
     * @returns {any}
     */
    const refreshTable = (iskeep = true) => {
      if (iskeep) {
        console.log('todo');
      } else {
        (erpTableRef.value as any).clearSort();
        (queryForm.value as any).sortColumnCn = null;
        (queryForm.value as any).sortType = null;
        paginationParams.pageNum = 1;
      }
      getTableDatas();
    };
    // 动态修改表格撑开高度
    const changeTableHeight = () => {
      // layoutHeight.value = (crmListRef.value as any).clientHeight;
      /** 重绘表格 */
    };
    /** */
    const initFilter = () => {
      let strongSort = window.localStorage.getItem(`${props.id}SortStrong`);
      if (strongSort) {
        strongSort = JSON.parse(strongSort);
        queryForm.value = Object.assign(queryForm.value, strongSort as any);
      }
      store.dispatch('getQueryParamsCache', props.id + 'ListQuery').then((data) => {
        if (data) {
          // 如果有缓存数据则使用缓存数据
          const { queryForm: queryParams, paginationParams: page, filterData } = data;
          paginationParams.pageNum = page.pageNum;
          paginationParams.pageSize = page.pageSize;

          queryForm.value = Object.assign(queryForm.value, queryParams);
          // 设置回填参数用
          (erpFilterRef.value as any).setColumnItem(filterData);
          // 如果自己有筛选参数，就重新set一次，不然下次点击进来不触发获取不到值
          if (filterData && filterData.length > 0) {
            checkItem.value = toRaw(filterData);
          }

          getTableDatas();
        } else {
          // 如果没有保留值 ，那在这里调用起获取和设置参数
          const Filter = erpFilterRef.value as any;
          Filter.initFilterData();
        }
      });
    };
    //改版表格布局
    const changeColumn = (option) => {
      tableOptions.value = option;
      formatFilterOption.value = option.map((v) => {
        v.value = ''; //清空筛选值
        return v;
      });
      setTimeout(() => {
        //等待组件更新
        const Filter = erpFilterRef.value as any;
        Filter.getRestParams(true); //筛选功能重置
      }, 100);
      refreshTable();
    };
    let option = XEUtils.clone(props.columOptions, true) as any;
    let cacheData = JSON.parse(window.localStorage.getItem(`${props.id}TableOption`) as any);
    if (props.hasConfig && cacheData) {
      //开启表格设置和有表格设置缓存的时候
      (option as any).forEach((item) => {
        cacheData.forEach((v) => {
          if (item.prop === v.prop) {
            item.showIndex = v.showIndex;
            item.$index = v.$index;
            item.align = v.align;
            item.isHidden = v.isHidden;
            item.fixed = v.fixed;
            item.isFilter = v.isFilter;
          }
        });
      });
    }
    tableOptions.value = option;
    formatFilterOption.value = option; //设置筛选字段
    watch(
      () => props.columOptions,
      (newVal) => {
        if (!props.hasConfig) tableOptions.value = toRaw(newVal) as any;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    // 执行初始化
    initFilter();
    return {
      tableOptions,
      restOptions,
      sort,
      rowClick,
      getAddItem,
      getQueryParams,
      getSortColumn,
      getColumn,
      paginationParams,
      ...toRefs(paginationParams),
      pageRef,
      layoutHeight,
      erpTableRef,
      crmListRef,
      visible,
      showVisible,
      erpFilterRef,
      queryForm,
      getTableDatas,
      queryParams,
      currentChange,
      currentSize,
      listDatas,
      refreshTable,
      completeLoad,
      formatFilterOption,
      filterLen,
      closeVisible,
      checkItem,
      changeColumn,
      selectChange,
    };
  },
});
