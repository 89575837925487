<template>
  <img class="table-config" @click="setTableOption" src="../../assets/images/icon/table-setting-icon.png" />
  <el-dialog title="列表设置" v-model="show" width="664px" :append-to-body="true" @close="close" custom-class="set-table-option">
    <div class="erp-config-top">
      <el-button type="text" class="top-btn" @click="recoveryDefault">恢复默认</el-button>
      <span>|</span>
      <el-button type="text" class="top-btn" @click="showAll">全部显示</el-button><span>|</span>
      <el-button type="text" class="top-btn" @click="fixedAll">全部解冻</el-button><span>|</span>
      <el-button type="text" class="top-btn" @click="up">上移</el-button><span>|</span> <el-button class="top-btn" type="text" @click="down">下移</el-button>
    </div>
    <el-table
      :data="tableOption"
      class="erp-table__list erp-config-table erp-form-table"
      height="453"
      border
      style="width: 664px"
      ref="configTable"
      :row-class-name="rowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="showIndex" width="60" label="序号" />
      <el-table-column align="left" prop="label" label="列名" width="240">
        <template #header>
          <div class="filter-name">
            列名
            <span @click="filterShow = true" class="icon-bg icon-filter"></span>
            <div v-if="filterShow" class="filter-box" @click.stop="filterShow = true">
              <el-input class="filter-input" v-model="labelSearch" placeholder="请输入列名" @input="seacherFilterName"></el-input>
            </div>
            <div v-if="filterShow" class="filter-box-model" @click="filterShow = false"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="对齐方式" width="110"
        ><template #default="scope"
          ><el-select v-model="scope.row.align" placeholder="对齐方式" style="width: 99px">
            <el-option label="默认" value="null"> </el-option>
            <el-option label="居左" value="left"> </el-option>
            <el-option label="居中" value="center"> </el-option>
            <el-option label="居右" value="right"> </el-option> </el-select
        ></template>
      </el-table-column>
      <el-table-column align="center" label="显示列" width="90"
        ><template #default="scope"><el-switch v-model="scope.row.isHidden" style="width: 34px" /></template>
      </el-table-column>
      <el-table-column align="center" label="冻结列" min-width="120"
        ><template #default="scope"><el-switch v-model="scope.row.fixed" @click="fixedCloum(scope)" style="width: 34px" /></template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="erp-config-bottom">
        <el-button class="foot-btn" type="primary" @click="setColumn()">确定</el-button>
        <el-button class="foot-btn" @click="close">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { errorMessage } from '@/utils/publicMethods';
import { ref } from 'vue';
import XEUtils from 'xe-utils';
export default {
  name: 'ErpConfig',
  props: {
    columOptions: {
      type: Array,
    },
    id: {
      type: String,
    },
  },
  emits: ['complete'],
  setup(props, ctx) {
    const configTable = ref(null); //列表对象
    const show = ref(false); //弹出框控制
    const filterShow = ref(false); //列名搜索弹窗
    const tableOption = ref([]); //列表内容
    const labelSearch = ref(''); //列名搜索
    let multipleSelection = []; //表格多选数据
    let stopOp = false; //中止移动操作
    //获取表格参数
    const setTableOption = () => {
      let options = [];
      labelSearch.value = '';
      let op = XEUtils.clone(props.columOptions, true);
      options = op.filter((v, index) => {
        v.showIndex = index + 1; //显示索引
        v.$index = index; //移动操作索引
        v.align = v.align !== undefined ? v.align : 'null';
        v.isHidden = v.isHidden !== undefined ? !v.isHidden : true; //true的时候为隐藏列，默认不隐藏为false,列表控制显示和实际相反
        v.fixed = v.fixed !== undefined ? v.fixed : false;
        v.isFilter = v.isFilter !== undefined ? v.isFilter : true; //控制表格显示字段
        return v.isFilter;
      });
      if (!window.localStorage.getItem(`${props.id}DefaultTableOption`)) {
        //存储默认配置
        window.localStorage.setItem(`${props.id}DefaultTableOption`, JSON.stringify(options));
      }
      tableOption.value = options;
      show.value = true;
    };
    //全部显示
    const showAll = () => {
      tableOption.value = tableOption.value.map((item) => {
        item.isHidden = true;
        return item;
      });
    };
    //关闭全部固定
    const fixedAll = () => {
      tableOption.value = tableOption.value.map((item) => {
        item.fixed = false;
        return item;
      });
    };
    //恢复默认
    const recoveryDefault = () => {
      let options = JSON.parse(window.localStorage.getItem(`${props.id}DefaultTableOption`));
      if (!options) return false;
      labelSearch.value = '';
      tableOption.value = options;
      // tableOption.value.forEach((item) => {
      //   options.forEach((v) => {
      //     if (item.prop === v.prop) {
      //       item.showIndex = v.showIndex;
      //       item.$index = v.$index;
      //       item.align = v.align;
      //       item.isHidden = v.isHidden;
      //       item.fixed = v.fixed;
      //       item.isFilter = v.isFilter;
      //     }
      //   });
      // });
    };
    //固定列操作
    const fixedCloum = (scope) => {
      tableOption.value.forEach((v, index) => {
        if (scope.row.fixed) {
          //打开固定列的时候把当列之前的也打开
          if (index < scope.$index) {
            v.fixed = true;
          }
        } else {
          //关闭固定列的时候把当列之后的也关闭
          if (index > scope.$index) {
            v.fixed = false;
          }
        }
      });
    };
    //重新设置选中数据
    const resetData = (ob) => {
      for (let item of multipleSelection) {
        if (item.prop === ob.prop) {
          item.$index = ob.$index;
        }
      }
    };
    //重新设置索引值
    const setIndex = () => {
      let showIndex = 1;
      tableOption.value.forEach((v, index) => {
        v.$index = index;
        if (v.isFilter) {
          v.showIndex = showIndex++;
        }
      });
    };
    /**
     * 数组元素交换位置
     * @param {array} arr 数组
     * @param {number} index1 添加项目的位置
     * @param {number} index2 删除项目的位置
     * index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
     */
    const swapArray = (arr, index1, index2) => {
      if (!arr[index1].fixed || !arr[index2].fixed) {
        //数据交换的时候当有一个为不固定列的时候，让两行数据都为不固定列
        arr[index1].fixed = false;
        arr[index2].fixed = false;
      }
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      setIndex();
      resetData(arr[index2]); //数据重置
      return arr;
    };

    //上移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位
    const zIndexDown = (arr, index, length) => {
      if (index + 1 != length && arr[index + 1]?.label !== '操作') {
        swapArray(arr, index, index + 1);
      } else {
        stopOp = true;
        return false;
        // errorMessage('导出的点击间隔请勿小于30秒');
        // alert('已经处于置底，无法下移');
      }
    };

    //下移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位
    const zIndexUp = (arr, index, length) => {
      if (index != 0) {
        swapArray(arr, index, index - 1);
      } else {
        stopOp = true;
        return false;
        // alert('已经处于置顶，无法上移');
      }
    };
    //获取选中的信息
    const handleSelectionChange = (val) => {
      const tableOb = configTable.value;
      multipleSelection = val.filter((v) => {
        if (v.isFilter) {
          tableOb.toggleRowSelection(v, true);
        } else {
          tableOb.toggleRowSelection(v, false);
        }
        return v.isFilter;
      }); //重新设置可筛选值;
    };
    //上移
    const up = () => {
      if (multipleSelection.length < 1) {
        errorMessage('请至少选中一行');
        return false;
      }
      stopOp = false;
      for (let item of multipleSelection) {
        let i = item.$index;
        let arr = tableOption.value;
        zIndexUp(arr, i, arr.length);
        if (stopOp) break;
      }
    };
    //下移
    const down = () => {
      if (multipleSelection.length < 1) {
        errorMessage('请至少选中一行');
        return false;
      }
      stopOp = false;
      //下移从后面数据开始
      for (let item of multipleSelection.reverse()) {
        let i = item.$index;
        let arr = tableOption.value;
        zIndexDown(arr, i, arr.length);
        if (stopOp) break;
      }
      multipleSelection.reverse(); //移动完成恢复数组
    };
    //验证;
    const verificationColumn = () => {
      let lastFixed = -1;
      let res = true;
      for (let index = 0; index < tableOption.value.length; index++) {
        let item = tableOption.value[index];
        // if (!item.isHidden && item.fixed) {
        //   errorMessage(`在第${index + 1}行中，隐藏与冻结一起会导致无效`);
        //   res = false;
        //   break;
        // }
        if (lastFixed === -1 && !item.fixed) {
          lastFixed = index; //获取第一个非冻结列
        }
        if (item.fixed && index > lastFixed && lastFixed !== -1) {
          errorMessage('冻结列需要在非冻结列之前');
          res = false;
          break;
        }
      }
      return res;
    };
    //确定
    const setColumn = () => {
      if (!verificationColumn()) return false;
      show.value = false;
      let option = XEUtils.clone(tableOption.value, true);
      option = option.map((item) => {
        item.isHidden = !item.isHidden; //列表控制显示和实际显示相反
        delete item.isFilter; //删除不必要数据
        delete item.showIndex; //删除不必要数据
        return item;
      });
      window.localStorage.setItem(`${props.id}TableOption`, JSON.stringify(option));
      ctx.emit('complete', option);
    };
    const seacherFilterName = (val) => {
      //搜索功能，通过isFilter改版行样式，隐藏行
      let index = 1;
      tableOption.value.forEach((v) => {
        if (v.label.indexOf(val) != -1) {
          v.showIndex = index++;
          v.isFilter = true;
        } else {
          v.isFilter = false;
        }
      });
      configTable.value.clearSelection();
    };
    const rowClassName = ({ row }) => {
      //表格过滤，隐藏行
      if ((!row.isFilter && row.isFilter !== undefined) || row.label === '操作') {
        return 'filter-row-hidden';
      } else {
        return '';
      }
    };
    const close = () => {
      show.value = false;
    };
    return {
      setColumn,
      configTable,
      setTableOption,
      handleSelectionChange,
      show,
      tableOption,
      showAll,
      fixedAll,
      fixedCloum,
      recoveryDefault,
      up,
      down,
      filterShow,
      labelSearch,
      seacherFilterName,
      rowClassName,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
.erp-config-top {
  text-align: right;
  padding: 0 20px;
  .top-btn {
    color: #2878ff;
    font-size: 16px;
  }
  span {
    color: #e5e9f2;
    padding: 0 16px;
  }
}
.erp-config-bottom {
  .foot-btn {
    height: 32px;
    min-height: 32px;
    line-height: 0;
    width: 80px;
  }
}
.table-config {
  cursor: pointer;
}
.filter-name {
  text-align: left;
  position: relative;
  .icon-filter {
    position: absolute;
    top: 1px;
    right: 5px;
    display: inline-block;
  }
  .filter-box {
    position: absolute;
    right: -50%;
    top: 20px;
    z-index: 3;
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
    border-radius: 2px;
    padding: 5px;
    .filter-input {
      width: 100%;
      color: #333;
      border-radius: 2px;
      padding: 0 5px;
      outline: none;
    }
  }
  .filter-box-model {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}
</style>
<style lang="scss">
.set-table-option {
  .el-dialog__body {
    padding: 0;
    padding-bottom: 30px;
  }
  .erp-config-table {
    .cell,
    th,
    .el-table__header-wrapper {
      overflow: visible !important;
    }
    .filter-row-hidden {
      display: none;
    }
  }
}
</style>