
import { useStore } from '@/store';
import { ConsType, ErpTableOptions, ISelectItem } from '@/types/type';
import { hasEmptyObject } from '@/utils/publicMethods';
import dayjs from 'dayjs';

import { computed, defineComponent, PropType, Ref, ref, toRaw, watch } from 'vue';
type PropItem = Partial<Pick<ErpTableOptions, 'type' | 'value' | 'prop' | 'options'>> & { filterProp?: any[] };
type CheckItem = {
  isRequired: boolean;
  prop: string;
  item: PropItem;
};
type AddFilterItem = {
  prop: string;
  value: string | any[];
};
export default defineComponent({
  name: 'CrmFilter',
  props: {
    // todo props;
    options: {
      type: Array as PropType<ErpTableOptions[]>,
    },
    title: {
      type: String as PropType<string>,
      default: '设置筛选',
    },
    submitText: {
      type: String as PropType<string>,
      default: '筛选',
    },
    isQuery: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['submit', 'load', 'close', 'addItem', 'rest'],
  setup(props, ctx) {
    const store = useStore();
    const load = ref(true);
    const restOptions = JSON.stringify(props.options);
    const dictOptions = computed(() => {
      return store.state.dict?.options;
    });
    const checkItems: Ref<CheckItem[]> = ref([]);
    const filterItems: Ref<ErpTableOptions[]> = ref([]);
    const errorIndexs: Ref<number[]> = ref([]);
    // 设置字典值,
    const setFilterItemOption = (options: ErpTableOptions[]): ErpTableOptions[] => {
      let op = options.filter((v) => {
        if (v.options && typeof v.options === 'string' && (v.type === 'signSelect' || v.type === 'multiSelect')) {
          const dict = dictOptions.value;
          const opts = (dict as ConsType)[v.options];
          v.options = opts as ISelectItem[];
        }
        return !v.isHidden && (v.isFilter || v.isFilter == undefined || v.isFilter == null); //有些设置里面没有isFilter。没有默认为true，isHidden字段不出现在筛选里面
      });
      return op;
    };

    const setCheckItem = (data: ErpTableOptions[]) => {
      const getCheckItems: CheckItem[] = [];
      data.forEach((v) => {
        if (!v.isHidden && (v.isFilter || v.isFilter == undefined || v.isFilter == null)) {
          const checkItem: CheckItem = { prop: '', isRequired: false, item: {} };
          // 判断是否有默认值
          if (typeof v.value === 'string' || typeof v.value === 'boolean' || typeof v.value === 'number') {
            if (v.value !== null && v.value !== '') {
              checkItem.prop = v.prop;
              checkItem.item = v;
              getCheckItems.push(checkItem);
            }
          } else if (typeof v.value === 'object') {
            if (v.value.length > 0) {
              checkItem.prop = v.prop;
              checkItem.item = v;
              getCheckItems.push(checkItem);
            }
          }
          checkItem.isRequired = v.isRequired ? v.isRequired : false;
        }
      });

      if (getCheckItems.length > 0) {
        return getCheckItems;
      } else {
        return [];
      }
    };
    //校验参数是否必填完整
    const validateError = () => {
      checkItems.value.forEach((checkItem, index) => {
        const v = checkItem.item;
        if (checkItem.prop !== '') {
          // 判断值是否必填
          if (v.value === '' || v.value === null || v.value === undefined) {
            errorIndexs.value.push(index);
          } else {
            switch (v.type) {
              case 'number':
                let numberVals = v.value as any[];
                if (numberVals.length <= 1) {
                  errorIndexs.value.push(index);
                } else {
                  if (
                    numberVals[0] === undefined ||
                    numberVals[0] === '' ||
                    numberVals[0] === null ||
                    numberVals[1] === undefined ||
                    numberVals[1] === '' ||
                    numberVals[1] === null
                  ) {
                    errorIndexs.value.push(index);
                  } else {
                    let currentIndex = errorIndexs.value.findIndex((v) => v === index);
                    errorIndexs.value.splice(currentIndex, 1);
                  }
                }
                break;
              case 'multiSelect':
                if ((v.value as any[]).length <= 0) {
                  errorIndexs.value.push(index);
                } else {
                  let currentIndex = errorIndexs.value.findIndex((v) => v === index);
                  errorIndexs.value.splice(currentIndex, 1);
                }
                break;
              default:
                let currentIndex = errorIndexs.value.findIndex((v) => v === index);
                errorIndexs.value.splice(currentIndex, 1);
            }
          }
        } else {
          // 判断筛选类型是否必填
          if (v.value !== '' || v.value.length > 0) {
            errorIndexs.value.push(index);
          }
        }
      });
    };
    const changeValidate = () => {
      validateError();
    };

    const changeItem = (index, data) => {
      checkItems.value[index].prop = data.prop;
    };

    const setColumnItem = (data: CheckItem[]) => {
      data = toRaw(data);

      // 这里回填回来的时候是没有下拉加载项的，所以这里重新获取下后台的数据
      if (data.length > 0) {
        data.forEach((v) => {
          if (v.item && v.item.options && typeof v.item.options === 'string' && (v.item.type === 'signSelect' || v.item.type === 'multiSelect')) {
            const dict = dictOptions.value;
            const opts = (dict as ConsType)[v.item.options];
            v.item.options = opts as ISelectItem[];
          }
        });
        checkItems.value = data;

        ctx.emit('addItem', checkItems.value.length, toRaw(checkItems.value));
      }
    };

    // 新增
    const addItem = () => {
      const params: CheckItem = {
        prop: '',
        isRequired: false,
        item: {
          type: 'input',
          prop: '',
          value: '',
        },
      };
      checkItems.value.push(params);
      validateError();
    };
    // 删除
    const delItem = (data: CheckItem, index) => {
      console.log('🚀 ~ file: ErpFilter.vue ~ line 316 ~ delItem ~ data', data);
      // const item = data.item;

      let item = checkItems.value[index].item;
      if (item.type === 'input' || item.type === 'signSelect') {
        data.item.value = '';
      } else {
        data.item.value = [];
      }
      if ((data.item as any).isHidden) {
        filterItems.value = filterItems.value.filter((v) => v.prop !== data.prop);
      }
      checkItems.value.splice(index, 1);
      setTimeout(() => {
        validateError();
      }, 0);
    };
    /**isColumnAdd 是否是表头新增筛选 */
    const clearItems = (isColumnAdd = false) => {
      checkItems.value = checkItems.value.filter((v) => {
        // 没有选择筛选项的才删除，而不是没有值才删除
        return v.prop !== '' && v.prop !== null;
      });
      // 如果为0则说明全部都被清空了，则新增一项空
      if (checkItems.value.length === 0) {
        if (!isColumnAdd) {
          addItem();
        }
      }
    };
    const close = () => {
      /** 清除没有value值的项 */
      clearItems();
      ctx.emit('close', true);
    };
    /** 表格选中添加 */
    const addColumnItem = (data) => {
      const len = checkItems.value.filter((v) => v.prop === data.prop).length;
      //需要清除空数据设置
      clearItems(true);
      if (len <= 0) {
        if (typeof data.item.options === 'string') {
          //防止新增的筛选字段的下拉值没有更新，重新设置字典值
          const dict = dictOptions.value;
          const opts = (dict as ConsType)[data.item.options];
          data.item.options = opts;
        }
        checkItems.value.push(data);
      }
    };
    /** 设置过滤重复项 */
    const disabledOption = (item) => {
      const len = checkItems.value.filter((v) => v.prop === item.prop).length;
      return len > 0;
    };

    // 设置获取参数信息
    const getQueryParams = (data: ErpTableOptions[], isSubmit = false, isFirst = false) => {
      const queryParams = {};
      checkItems.value.forEach((checkItem) => {
        // 设置为isHidden为 true的不加载
        const v = checkItem.item;

        if (v.type === 'input' || v.type === 'signSelect' || v.type === 'multiSelect') {
          if (v.type === 'multiSelect') {
            if ((v.value as any[]).length > 0) {
              queryParams[v.prop as string] = v.value;
            }
          } else {
            if (v.value !== '' && v.value !== null) {
              queryParams[v.prop as string] = v.value;
            }
          }
        }
        if (v.type === 'time') {
          if (v.value && v.value[0]) {
            queryParams[(v.filterProp as any)[0]] = dayjs(v.value[0]).format('YYYY-MM-DD');
            queryParams[(v.filterProp as any)[1]] = dayjs(v.value[1]).format('YYYY-MM-DD');
          }
        }
        if (v.type === 'month') {
          if (v.value && v.value[0]) {
            queryParams[(v.filterProp as any)[0]] = dayjs(v.value[0]).format('YYYY-MM');
            queryParams[(v.filterProp as any)[1]] = dayjs(v.value[1]).format('YYYY-MM');
          }
        }

        if (v.type === 'number') {
          const value0 = (v.value as any)[0] ? (v.value as any)[0] : null;
          const value1 = (v.value as any)[1] ? (v.value as any)[1] : null;

          if (value0 !== '' && value1 !== '' && value0 !== null && value1 !== null) {
            if (Number(value0) > Number(value1)) {
              queryParams[(v.filterProp as any)[0]] = value1;
              queryParams[(v.filterProp as any)[1]] = value0;
              // 点击后自动置换
              if (v.value) {
                v.value[0] = value1;
                v.value[1] = value0;
              }
            } else {
              queryParams[(v.filterProp as any)[0]] = value0;
              queryParams[(v.filterProp as any)[1]] = value1;
            }
          } else {
            if (value0 !== null) {
              queryParams[(v.filterProp as any)[0]] = value0;
            }
            if (value1 !== null) {
              queryParams[(v.filterProp as any)[1]] = value1;
            }
          }
        }
        validateError();
      });

      // isFirst 为真则将默认参数保留起来
      //clearItems();
      const filterCheckItems = checkItems.value.filter((v) => {
        return v.prop && v.prop !== '';
      });
      if (errorIndexs.value.length > 0) {
        return false;
      } else {
        errorIndexs.value = [];
        ctx.emit('submit', queryParams, filterCheckItems, isSubmit, isFirst);
      }

      return queryParams;
    };
    /** 重置参数方法 */
    const getRestParams = (isChange = false) => {
      let opts = [];
      if (isChange) {
        //筛选可选择项更新
        opts = JSON.parse(JSON.stringify(props.options));
      } else {
        //筛选可选择项不更新
        opts = JSON.parse(restOptions).map((item) => {
          props.options?.forEach((v) => {
            //获取表格控制中隐藏的字段，在筛选的下拉框选项中隐藏掉
            if (item.prop === v.prop) {
              item.isHidden = v.isHidden;
            }
          });
          return item;
        });
      }
      filterItems.value = setFilterItemOption(opts);
      checkItems.value = setCheckItem(opts) as CheckItem[];
      if (checkItems.value.length === 0) {
        addItem();
      }
      errorIndexs.value = [];
      ctx.emit('rest', opts);
      if (!props.isQuery) {
        return getQueryParams(opts, true);
      }
    };
    /** 设置手动新增过滤条件方法 */
    const addFilterItem = (data: AddFilterItem[]) => {
      let op = JSON.parse(restOptions); //原始默认值
      data.forEach((v) => {
        const index = checkItems.value.findIndex((checkItem) => checkItem.prop === v.prop);
        // 筛选项已经存在就改变选中值
        if (index >= 0) {
          checkItems.value[index].item.value = v.value;
        } else {
          // 不存在就添加进去
          const params: CheckItem = {
            prop: v.prop,
            isRequired: false,
            item: op.filter((filterItem) => {
              //当添加的是被隐藏的筛选条件时候，在筛选list里添加被隐藏的项
              if (typeof filterItem.options === 'string') {
                //防止新增的筛选字段的下拉值没有更新，重新设置字典值
                const dict = dictOptions.value;
                const opts = (dict as ConsType)[filterItem.options];
                filterItem.options = opts;
              }
              if (filterItem.prop === v.prop && filterItem.isHidden) {
                //当添加的是被隐藏的筛选条件时候，在筛选list里添加被隐藏的项
                filterItems.value.push(filterItem);
              }
              return filterItem.prop === v.prop;
            })[0], //从默认值取，避免被隐藏了
            // item: filterItems.value.filter((filterItem) => filterItem.prop === v.prop)[0],
          };
          params.item.value = v.value;
          checkItems.value.push(params);
        }
      });
      ctx.emit('addItem', checkItems.value.length, toRaw(checkItems.value));
    };
    // 初始化过滤数值
    const initFilterData = (isDefaultQuery = true) => {
      checkItems.value = setCheckItem(props.options as ErpTableOptions[]) as CheckItem[];
      if (isDefaultQuery) {
        getQueryParams(props.options as ErpTableOptions[], false, true);
      }
      // 默认加一个空项
      addItem();
    };
    // 设置取值范围
    const disabledDateFn = (date: Date) => {
      // 这里考虑怎么设计抽离出去，proxy不好传递函数
      // 限制返回时间为3年前
      const beforeTime = dayjs().subtract(36, 'month');
      return dayjs().isBefore(date, 'month') || beforeTime.isAfter(date, 'month');
    };
    // 默认触发一次
    // 只有保证获取到了字典以后再去渲染，避免一些时间冲突问题
    const changeNumber = (e: string, data: PropItem, index: number) => {
      let value = e.slice(0, 9);
      if (data.value) {
        data.value[index] = value;
      }
      validateError();
    };

    watch(
      dictOptions,
      () => {
        // 一定要有值以后再来初始化组件
        load.value = false;
        if (!hasEmptyObject(dictOptions.value)) {
          filterItems.value = setFilterItemOption(props.options as ErpTableOptions[]);
          load.value = true;
          ctx.emit('load', true);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      changeValidate,
      errorIndexs,
      changeNumber,
      disabledDateFn,
      addColumnItem,
      setColumnItem,
      initFilterData,
      addFilterItem,
      filterItems,

      getQueryParams,
      load,
      getRestParams,
      checkItems,
      addItem,
      changeItem,
      disabledOption,
      delItem,
      close,
    };
  },
});
