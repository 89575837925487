
import { CrmFilterOptions } from '@/types/type';
import ErpConfig from './ErpConfig.vue';
import { computed, defineComponent, nextTick, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue';
interface Sort {
  sortColumnCn: string | null;
  sortType: null | 1 | 2;
}
export default defineComponent({
  name: 'CrmTable',
  components: { ErpConfig },
  props: {
    data: {
      type: Array as PropType<any[]>,
    },
    id: {
      type: String as PropType<string>,
    },
    columOptions: {
      type: Array as PropType<any[]>,
    },
    layoutHeight: {
      type: Number as PropType<number>,
      default: 880,
    },
    maxHeight: {
      type: Number as PropType<number>,
      default: 99999,
    },
    checkItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    colorRander: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    //表格设置功能
    hasConfig: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // todo props;
  },
  emits: ['columnClick', 'sortColumn', 'row-click', 'noop', 'changeColumn', 'select-change'],
  setup(props, ctx) {
    const table = ref(null);
    const listData = ref([]);
    const sort: Sort = reactive({
      sortType: null,
      sortColumnCn: null,
    });
    const showDefaultColumn = ref(true); //空数据默认行
    const selectChange = (selection) => {
      ctx.emit('select-change', selection);
    };
    const filterNames = computed(() => {
      if (props.checkItems && props.checkItems.length > 0) {
        return (props.checkItems as any).map((v) => v.prop);
      } else {
        return [];
      }
    });

    const tableOption = computed(() => {
      let widthStrong = window.localStorage.getItem(`${props.id}WidthStrong`);
      if (widthStrong) {
        widthStrong = JSON.parse(widthStrong as string);
        const options: CrmFilterOptions[] = [];
        (props.columOptions as CrmFilterOptions[]).forEach((v, index) => {
          // 控制下最小的宽度，不要完全看不清字
          props.selection && index++; //带有复选框的，第一个值为复选框的值，不用取
          v.minWidth = Number((widthStrong as string)[index]) < 80 ? 80 : Number((widthStrong as string)[index]);
          options.push(v);
        });
        return options;
      } else {
        return props.columOptions;
      }
    });
    const tableHeight = computed(() => {
      return props.layoutHeight - 80;
    });
    const strongSort = window.localStorage.getItem(`${props.id}SortStrong`);
    if (strongSort) {
      const strongSortObj: Sort = JSON.parse(strongSort);
      sort.sortColumnCn = strongSortObj?.sortColumnCn;
      sort.sortType = strongSortObj?.sortType;
    }
    const rowClick = (row, column, event) => {
      ctx.emit('row-click', row, column, event);
    };
    const getBind = ({ row, column, $index }, col) => {
      const props = { row, col, column, index: $index };
      return props;
    };
    const headerDragend = () => {
      nextTick(() => {
        const el = (table.value as any).$el.querySelector('colgroup').querySelectorAll('col');
        const widthStrong: any = [];
        el.forEach((v) => {
          widthStrong.push(v.getAttribute('width'));
        });
        window.localStorage.setItem(`${props.id}WidthStrong`, JSON.stringify(widthStrong));
      });
    };
    const sortChange = (item) => {
      if (item.label !== '操作' && (item.isSort === undefined || item.isSort === true)) {
        if (sort.sortColumnCn !== item.label) {
          sort.sortType = null;
        }
        sort.sortColumnCn = item.label;
        switch (sort.sortType) {
          case null:
            sort.sortType = 1;
            break;
          case 1:
            sort.sortType = 2;
            break;
          case 2:
            sort.sortColumnCn = null;
            sort.sortType = null;
            break;
          default:
            sort.sortType = null;
            break;
        }
        // 保存进本地信息

        ctx.emit('sortColumn', JSON.parse(JSON.stringify(sort)));
      }
    };
    const showHeaderFilter = (item) => {
      const data = item;
      ctx.emit('columnClick', { prop: data.prop, item: data });
    };

    const isNull = (row, prop) => {
      const txt = row[prop];
      if (txt === null || txt === '') {
        return '-';
      } else {
        return txt;
      }
    };
    const clearSort = () => {
      window.localStorage.removeItem(`${props.id}SortStrong`);
      sort.sortType = null;
      sort.sortColumnCn = null;
    };
    const setCellClassName = ({ row, column }) => {
      if (props.colorRander && column.label !== '操作') {
        if (row.color) {
          return row.color;
        }
      }
    };
    //改版表格布局
    const changeColumn = (option) => {
      ctx.emit('changeColumn', option);
    };
    watch(
      //监听表格数据，当所有列都隐藏的时候，显示一个空列来占位
      tableOption,
      () => {
        showDefaultColumn.value = true;
        tableOption.value?.forEach((item) => {
          if (!item.isHidden) {
            //当有一列不是隐藏的时候就隐藏空列
            showDefaultColumn.value = false;
            return;
          }
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
    onMounted(() => {
      (table.value as any).doLayout();
    });

    return {
      setCellClassName,
      rowClick,
      filterNames,
      ...toRefs(sort),
      sortChange,
      showHeaderFilter,
      listData,
      getBind,
      table,
      tableHeight,
      headerDragend,
      tableOption,
      isNull,
      clearSort,
      changeColumn,
      selectChange,
      showDefaultColumn,
    };
  },
});
